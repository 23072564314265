import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.scss';

import App from './App'
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#fd7e14',
          colorSplit: '#367fa9',
        }
      }}
    >
      <App/>
    </ConfigProvider>
  </React.StrictMode>
);

import React, { useState } from 'react';
import {
  DesktopOutlined,
  PieChartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme, Typography, Divider } from 'antd';
import css from './App.module.scss'
import FIDTIcon from './assets/logo.fidt.top-menu.png'

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Thống kê', '1', <PieChartOutlined />),
  getItem('Voucher', '2', <DesktopOutlined />),
  getItem('Danh sách đơn hàng', '3', <UserOutlined />),
  getItem('Quản lý sales', '4', <UserOutlined />),
];

const SIDE_BAR_MENU_WIDTH = 200;

const App: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Header
        className={css['top-header-app']}
      >
          <div
            style={{
              width: SIDE_BAR_MENU_WIDTH
            }}
          >
            <img
              src={FIDTIcon}
            />
          </div>
      </Header>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          width={SIDE_BAR_MENU_WIDTH}
          className={css['side-bar-menu']}
        >
          <div
            style={{
              textAlign: 'center',
              marginTop: 20,
              marginBottom: 20
            }}
          >
            <UserOutlined
              style={{
                color: 'white',
                fontSize: 50
              }}
            />
            <Title
              level={5}
              style={{
                color: 'white',
                marginTop: 10
              }}
            >
              Nguyễn Thành Văn
            </Title>
          </div>
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 10,
              borderWidth: 5
            }}
          />
          <Menu
            theme="dark"
            defaultSelectedKeys={['1']}
            mode="inline"
            items={items}
            
            className={css['side-menu-main']}
          />
          <Divider
            style={{
              marginTop: 0,
              marginBottom: 0,
              borderWidth: 5
            }}
          />
          <div
            style={{
              marginTop: 10,
              textAlign: 'center'
            }}
          >
            <Title
              style={{
                color: 'white',
              }}
              level={5}
            >
              Đăng xuất
            </Title>
          </div>
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }} />
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
              Bill is a cat.
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default App;